import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const hypothequeSlice = createSlice({
  name: "hypotheque",
  initialState,
  reducers: {},
});

export const {} = hypothequeSlice.actions;

export default hypothequeSlice.reducer;
