import { Alert, Label } from "flowbite-react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import PhoneIcon from "../app/assets/icons/PhoneIcon";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import CustomSelect from "./CustomSelect";
import { useTranslation } from "react-i18next";

type PayloadType = {
  phone: string;
  category: string;
};

type Props = {
  isReminder?: boolean;
};

const AdvisorCallForm = ({ isReminder = false }: Props) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="mb-4">
        <Label
          className="text-base leading-5"
          value={t("AdvisorCallForm.key1")}
        />
        <div className="my-[14px] h-[56px] rounded-lg border-[1.5px] border-[#88889459] bg-white px-[14px] focus-within:border-2 focus-within:border-primary [&>input[type=tel]]:focus-within:outline-none PhoneInput">
          <PhoneInputWithCountry
            name="phone"
            control={control}
            rules={{ required: true, validate: isPossiblePhoneNumber }}
            defaultCountry="CH"
            autoComplete="false"
            placeholder={t("AdvisorCallForm.key2")}
          />
        </div>
      </div>

      {!isReminder && (
        <div className="mb-4">
          <Label
            className="text-base leading-5"
            value={t("AdvisorCallForm.key3")}
          />
          <CustomSelect
            name="category"
            control={control}
            rules={{ required: true }}
            options={[
              {
                label: t("AdvisorCallForm.key4"),
                value: "health-insurance",
              },
              {
                label: t("AdvisorCallForm.key5"),
                value: "car-insurance",
              },
              {
                label: t("AdvisorCallForm.key6"),
                value: "legal-protection",
              },
              {
                label: t("AdvisorCallForm.key7"),
                value: "home-insurance",
              },
              { label: t("AdvisorCallForm.key8"), value: "mortgage" },
              { label: t("AdvisorCallForm.key9"), value: "pension" },
            ]}
          />
        </div>
      )}

      <Alert
        color=""
        className="mb-1 relative w-full rounded-xl py-[14px] px-[24px] [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 bg-[#EBF5FF] !text-[#2D50E6] [&>svg]:text-[#2D50E6]"
      >
        {t("AdvisorCallForm.key10")}
      </Alert>

      <button
        type="submit"
        className="w-full inline-flex items-center justify-center whitespace-nowrap rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[56px] px-[26px] mt-[14px]"
        disabled={!isValid}
      >
        <PhoneIcon />
        {!isReminder ? t("AdvisorCallForm.key11") : t("AdvisorCallForm.key12")}
      </button>
    </form>
  );
};

export default AdvisorCallForm;
