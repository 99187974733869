import { Dropdown } from "flowbite-react";
import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import ChevronDownIcon from "../app/assets/icons/ChevronDownIcon";
import { HiCheck } from "react-icons/hi2";

type Props = {
  control: Control;
  name: string;
  options: Array<{ value: string; label: string }>;
  placeholder?: string;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
};

const CustomSelect = ({
  control,
  name,
  options,
  rules,
  placeholder,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState: { errors } }) => (
        <Dropdown
          label=""
          renderTrigger={() => (
            <div className="my-[14px] h-[56px] cursor-pointer flex items-center justify-between rounded-lg border-[1.5px] border-[#88889459] bg-white px-[14px] focus-within:border-2 focus-within:border-primary [&>input[type=tel]]:focus-within:outline-none">
              <div>
                {field.value ? (
                  options.find((option) => option.value === field.value)?.label
                ) : (
                  <p className="text-left text-gray-500">
                    {placeholder ? placeholder : "Choose a service"}
                  </p>
                )}
              </div>
              <ChevronDownIcon />
            </div>
          )}
        >
          {options.map((option) => (
            <Dropdown.Item
              key={option.value}
              onClick={() => field.onChange(option.value)}
            >
              <div className="w-6">
                {field.value && option.value === field.value ? <HiCheck /> : ""}
              </div>

              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown>
      )}
    />
  );
};

export default CustomSelect;
