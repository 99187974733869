import { combineReducers, configureStore } from "@reduxjs/toolkit";
import assuranceVoitureSlice from "../lib/feature/assuranceVoiture/slice";
import assuranceSanteSlice from "../lib/feature/assuranceSante/slice";
import protectionJuridiqueSlice from "../lib/feature/protectionJuridique/slice";
import prevoyanceSlice from "../lib/feature/prevoyance/slice";
import assuranceMenageSlice from "../lib/feature/assuranceMenage/slice";
import hypothequeSlice from "../lib/feature/hypotheque/slice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import storage from "./ssr-safe-storage";
import { v1Api } from "./services/v1";
import monitorServicesErrors from "./middlewares/monitorServicesErrors";

const persistConfig = {
  key: "state",
  whiteList: ["services"],
  storage,
  version: 1,
  migrate: (state: any) => {
    console.log("Migration Running!");
    return Promise.resolve(state);
  },
};

const servicesReducer = combineReducers({
  assuranceVoiture: assuranceVoitureSlice,
  assuranceSante: assuranceSanteSlice,
  protectionJuridique: protectionJuridiqueSlice,
  prevoyance: prevoyanceSlice,
  assuranceMenage: assuranceMenageSlice,
  hypotheque: hypothequeSlice,
});

const persistedReducer = persistReducer(persistConfig, servicesReducer);

export const makeStore = () => {
  return configureStore({
    reducer: {
      services: persistedReducer,
      [v1Api.reducerPath]: v1Api.reducer,
    },
    // devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(v1Api.middleware, monitorServicesErrors),
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
