import {
  Dispatch,
  Middleware,
  MiddlewareAPI,
  UnknownAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { checkIfErrorIsResolved } from "../feature/assuranceVoiture/slice";

const monitorServicesErrors: Middleware =
  (store: MiddlewareAPI<Dispatch<UnknownAction>, RootState>) =>
  (next) =>
  (action: any) => {
    next(action);

    if (action.type === "assuranceVoiture/updateData") {
      const errors = store.getState().services.assuranceVoiture.stepWithError;

      if (errors.length > 0) {
        errors.forEach((error) => {
          store.dispatch(checkIfErrorIsResolved(error));
        });
      }
    }
  };

export default monitorServicesErrors;
