import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const prevoyanceSlice = createSlice({
  name: "prevoyance",
  initialState,
  reducers: {},
});

export const {} = prevoyanceSlice.actions;

export default prevoyanceSlice.reducer;
