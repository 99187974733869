function generateUUIDv4() {
  return crypto.randomUUID();
}

function scrollToElementWithOffset(element: HTMLElement, offset = 0) {
  const elementTop = element.getBoundingClientRect().top;
  const scrollTop = elementTop + offset;

  window.scrollTo({
    top: scrollTop,
    behavior: "smooth",
  });
}

const helpers = {
  generateUUIDv4,
  scrollToElementWithOffset,
};

export default helpers;
