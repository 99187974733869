import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const protectionJuridiqueSlice = createSlice({
  name: "protectionJuridique",
  initialState,
  reducers: {},
});

export const {} = protectionJuridiqueSlice.actions;

export default protectionJuridiqueSlice.reducer;
