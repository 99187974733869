import { v1Api } from "@/lib/services/v1";
import { ISharedServiceState } from "@/lib/types";
import helpers from "@/utils/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface CarBuyDate {}
export interface CarOption {
  value: string;
  brand: string;
  logo: string;
  from: number;
  to: number;
  registrationYears: number[];
  label: string;
}
export interface DetailsAddress {
  street: string;
  address_number: string;
  country: string;
  place: string;
  postcode: string;
  region: string;
  region_code: string;
}
export interface CarInfos {
  newValueAccessories: number;
  matriculationDate: number;
  catalogPrice: number;
  modelId: number;
  etxCode: number;
  description: string;
  buildType: string;
  capacity: number;
  doors: number;
  seats: string;
  emptyLoad: number;
  horsePower: number;
  enginePowerInKw: number;
  typeCertificate: string;
  vehicleType: string;
  vehicleClass: number;
  fuel: string;
  brand: string;
  model: string;
  emobility: boolean;
}

export interface IAssuranceVoitureInitialState extends ISharedServiceState {
  data: {
    nom: string;
    prenom: string;
    phone: string;
    email: string;
    dob: string;
    car_buy_date?: CarBuyDate;
    car_option?: CarOption;
    detailsAddress?: DetailsAddress;
    car_type?: string;
    car_brand?: string;
    car_registration_month?: string;
    car_registration_year?: number;
    car_infos?: CarInfos;
    car_list_types_certificates?: any[];
    car_distance?: string;
    car_usage?: string;
    car_park_place?: string;
    car_park_type?: string;
    civility?: string;
    nationality?: string;
    residency_type?: string;
    eco_assurance_menage?: boolean;
    car_leasing?: boolean;
    needs?: string;
    coverage?: string[];
    contract_start?: string;
    already_assure?: boolean;
  };
}

type PayloadPossibleTypes =
  | string
  | number
  | boolean
  | CarBuyDate
  | CarOption
  | DetailsAddress
  | CarInfos
  | any[];

const initialState: IAssuranceVoitureInitialState = {
  versionId: "",
  _start: "",
  data: {
    nom: "",
    prenom: "",
    phone: "",
    email: "",
    dob: "",
  },
  stepWithError: [],
  currentStep: {
    id: "car_type",
    group: "my_car",
    branch: "standard",
  },
  currentVisibleStep: {
    id: "car_type",
    group: "my_car",
    branch: "standard",
  },
  loaded: false,
  errors: {},
};

const assuranceVoitureSlice = createSlice({
  name: "assuranceVoiture",
  initialState,
  reducers: {
    reset: () => initialState,

    initWizard: (state) => {
      state.versionId = helpers.generateUUIDv4();
      state._start = new Date().toISOString();
      state.loaded = true;
    },

    updateData: (
      state,
      action: PayloadAction<{
        data: {
          item: keyof IAssuranceVoitureInitialState["data"];
          value: PayloadPossibleTypes;
        };
        currentStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
        currentVisibleStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
      }>
    ) => {
      const { item, value } = action.payload.data;

      const updatedData = {
        ...state.data,
        [item]: value as IAssuranceVoitureInitialState["data"][typeof item],
      };

      state.data = updatedData;

      if (
        action.payload.currentStep &&
        state.currentStep.id !== "info" &&
        state.currentStep.group !== "my_contact"
      ) {
        state.currentStep.id = action.payload.currentStep.id;

        if (action.payload.currentStep.group)
          state.currentStep.group = action.payload.currentStep.group;

        if (action.payload.currentStep.branch)
          state.currentStep.branch = action.payload.currentStep.branch;

        if (action.payload.currentStep.newTab)
          state.currentStep.newTab = action.payload.currentStep.newTab;
        else delete state.currentStep.newTab;
      }

      if (action.payload.currentVisibleStep) {
        state.currentVisibleStep.id = action.payload.currentVisibleStep.id;

        if (action.payload.currentVisibleStep.group)
          state.currentVisibleStep.group =
            action.payload.currentVisibleStep.group;

        if (action.payload.currentVisibleStep.branch)
          state.currentVisibleStep.branch =
            action.payload.currentVisibleStep.branch;

        if (action.payload.currentVisibleStep.newTab)
          state.currentVisibleStep.newTab =
            action.payload.currentVisibleStep.newTab;
        else delete state.currentVisibleStep.newTab;
      }
    },

    updateCurrentVisibleStep: (
      state,
      action: PayloadAction<{
        id: string;
        group?: string;
        branch?: string;
        newTab?: boolean;
      }>
    ) => {
      if (state.stepWithError.length > 0)
        toast.error("Please correct the errors before proceeding.");
      else
        state.currentVisibleStep = {
          ...state.currentVisibleStep,
          ...action.payload,
        };
    },

    addStepError: (state, action: PayloadAction<string>) => {
      if (state.stepWithError.indexOf(action.payload) === -1)
        state.stepWithError.push(action.payload);
    },

    removeStepError: (state, action: PayloadAction<string>) => {
      state.stepWithError = state.stepWithError.filter(
        (item) => item !== action.payload
      );
    },

    checkIfErrorIsResolved: (state, action: PayloadAction<string>) => {
      if (
        Object.hasOwn(state.data, action.payload) &&
        state.data[
          action.payload as keyof IAssuranceVoitureInitialState["data"]
        ] !== "" &&
        state.stepWithError.indexOf(action.payload) !== -1
      ) {
        state.stepWithError = state.stepWithError.filter(
          (item) => item !== action.payload
        );
      }
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      v1Api.endpoints.getModelInfo.matchFulfilled,
      (state, action) => {
        state.data.car_infos = action.payload;
      }
    );
  },
});

export const {
  reset,
  initWizard,
  updateData,
  updateCurrentVisibleStep,
  addStepError,
  removeStepError,
  checkIfErrorIsResolved,
} = assuranceVoitureSlice.actions;

export default assuranceVoitureSlice.reducer;
