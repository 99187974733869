import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const assuranceSanteSlice = createSlice({
  name: "assuranceSante",
  initialState,
  reducers: {},
});

export const {} = assuranceSanteSlice.actions;

export default assuranceSanteSlice.reducer;
