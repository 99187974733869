import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const assuranceMenageSlice = createSlice({
  name: "assuranceMenage",
  initialState,
  reducers: {},
});

export const {} = assuranceMenageSlice.actions;

export default assuranceMenageSlice.reducer;
