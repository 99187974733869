"use client";

import PhoneIcon from "@/app/assets/icons/PhoneIcon";
import AdvisorCallForm from "@/components/AdvisorCallForm";
import LanguageChanger from "@/components/LanguageChanger";
import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ComperaTxt = () => {
  return (
    <svg
      width="146"
      height="30"
      viewBox="0 0 146 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_771_8469)">
        <path
          d="M11.6371 23.3084C9.36114 23.3084 7.4069 22.8198 5.77439 21.8403C4.14665 20.8632 2.89069 19.5 2.0089 17.7507C1.13187 16.0038 0.693359 13.9805 0.693359 11.6783C0.693359 9.38562 1.13187 7.3575 2.0089 5.5963C2.89069 3.83748 4.14665 2.46474 5.77439 1.47809C7.4069 0.493816 9.36114 0.000488281 11.6371 0.000488281C14.3445 0.000488281 16.5585 0.672557 18.2815 2.01193C20.0094 3.35368 21.089 5.23405 21.5227 7.6554H16.9851C16.7039 6.43519 16.108 5.47713 15.1929 4.77647C14.2825 4.07818 13.0766 3.72785 11.5704 3.72785C9.49222 3.72785 7.8621 4.43805 6.68002 5.85368C5.49794 7.2717 4.9069 9.21403 4.9069 11.6783C4.9069 14.1521 5.49794 16.0896 6.68002 17.4933C7.8621 18.8994 9.49222 19.6001 11.5704 19.6001C13.0766 19.6001 14.2825 19.2736 15.1929 18.6182C16.108 17.9652 16.7039 17.0596 16.9851 15.9014H21.5227C21.089 18.2226 20.0094 20.0362 18.2815 21.3446C16.5585 22.6554 14.3445 23.3084 11.6371 23.3084Z"
          fill="#082623"
        ></path>
        <path
          d="M30.482 23.3089C28.9376 23.3089 27.5482 22.9561 26.3161 22.2507C25.0816 21.5405 24.1045 20.5586 23.38 19.305C22.6602 18.0467 22.3027 16.5929 22.3027 14.939C22.3027 13.2803 22.6674 11.8265 23.399 10.5729C24.1283 9.31457 25.1102 8.33269 26.3447 7.62725C27.5768 6.91705 28.9686 6.55957 30.5201 6.55957C32.0382 6.55957 33.4133 6.91705 34.6478 7.62725C35.8871 8.33269 36.8642 9.31457 37.584 10.5729C38.3013 11.8265 38.6612 13.2803 38.6612 14.939C38.6612 16.5929 38.3013 18.0467 37.584 19.305C36.8642 20.5586 35.8823 21.5405 34.6383 22.2507C33.3919 22.9561 32.0072 23.3089 30.482 23.3089ZM30.482 19.734C31.5544 19.734 32.4886 19.3313 33.2846 18.5233C34.0782 17.7178 34.4762 16.5214 34.4762 14.939C34.4762 13.3518 34.0782 12.153 33.2846 11.3451C32.4886 10.5396 31.5687 10.1344 30.5201 10.1344C29.419 10.1344 28.48 10.5396 27.6984 11.3451C26.9167 12.153 26.5258 13.3518 26.5258 14.939C26.5258 16.5214 26.9167 17.7178 27.6984 18.5233C28.48 19.3313 29.4071 19.734 30.482 19.734Z"
          fill="#0CBCB0"
        ></path>
        <path
          d="M40.0918 22.9175V6.94993H43.7238L44.0765 9.10436C44.5913 8.33697 45.2705 7.71971 46.1166 7.25498C46.9674 6.79264 47.9517 6.55908 49.0718 6.55908C51.536 6.55908 53.2853 7.51714 54.3149 9.42848C54.8916 8.55146 55.6709 7.85556 56.6504 7.34078C57.6275 6.82124 58.6952 6.55908 59.8535 6.55908C61.9364 6.55908 63.5356 7.18349 64.6485 8.42753C65.7663 9.67395 66.3263 11.4971 66.3263 13.8994V22.9175H62.2081V14.2903C62.2081 12.9175 61.9436 11.8665 61.4169 11.1349C60.8878 10.4056 60.0775 10.0386 58.986 10.0386C57.8659 10.0386 56.9674 10.4485 56.2882 11.2683C55.6137 12.0834 55.2777 13.2178 55.2777 14.6716V22.9175H51.1595V14.2903C51.1595 12.9175 50.8878 11.8665 50.3492 11.1349C49.8153 10.4056 48.9908 10.0386 47.8802 10.0386C46.7863 10.0386 45.8997 10.4485 45.2205 11.2683C44.546 12.0834 44.21 13.2178 44.21 14.6716V22.9175H40.0918Z"
          fill="#082623"
        ></path>
        <path
          d="M68.041 30.0009V6.95042H71.7016L72.1592 9.23831C72.674 8.52811 73.3532 7.90371 74.1993 7.36987C75.0501 6.83126 76.1487 6.55957 77.4976 6.55957C79.0038 6.55957 80.3432 6.92659 81.5205 7.65585C82.7026 8.3875 83.6368 9.38845 84.3232 10.6587C85.0096 11.9242 85.3528 13.3613 85.3528 14.9676C85.3528 16.5763 85.0096 18.0086 84.3232 19.2669C83.6368 20.5205 82.7026 21.5071 81.5205 22.2316C80.3432 22.949 79.0038 23.3089 77.4976 23.3089C76.2965 23.3089 75.2431 23.0824 74.3423 22.632C73.4462 22.1816 72.7169 21.5453 72.1592 20.7254V30.0009H68.041ZM76.6302 19.6959C77.9385 19.6959 79.0229 19.2574 79.8809 18.3803C80.7388 17.4986 81.1678 16.3594 81.1678 14.9676C81.1678 13.571 80.7388 12.4223 79.8809 11.5262C79.0229 10.6253 77.9385 10.1725 76.6302 10.1725C75.3003 10.1725 74.2112 10.6182 73.3604 11.5071C72.5143 12.3985 72.0925 13.5424 72.0925 14.939C72.0925 16.3308 72.5143 17.4723 73.3604 18.3613C74.2112 19.2526 75.3003 19.6959 76.6302 19.6959Z"
          fill="#082623"
        ></path>
        <path
          d="M92.3089 23.3089C90.9362 23.3089 89.8065 23.0896 88.9247 22.6511C88.0477 22.2078 87.3923 21.6192 86.961 20.8875C86.5344 20.1582 86.3223 19.3527 86.3223 18.4757C86.3223 16.9957 86.899 15.7946 88.0573 14.8722C89.2203 13.9523 90.9576 13.49 93.2717 13.49H97.3328V13.0991C97.3328 12.0076 97.0206 11.2021 96.3985 10.6873C95.7741 10.1725 95.002 9.91515 94.082 9.91515C93.2431 9.91515 92.5115 10.1153 91.8895 10.5157C91.2651 10.9113 90.879 11.4928 90.7265 12.2602H86.7036C86.8108 11.1044 87.2017 10.0963 87.8761 9.23831C88.5553 8.38035 89.4324 7.7202 90.5072 7.25547C91.5797 6.79313 92.7808 6.55957 94.1106 6.55957C96.3842 6.55957 98.1764 7.12916 99.4872 8.26596C100.796 9.40514 101.451 11.0162 101.451 13.0991V22.918H97.9429L97.552 20.3441C97.0801 21.2021 96.42 21.9123 95.5692 22.47C94.7231 23.0276 93.6364 23.3089 92.3089 23.3089ZM93.2431 20.0867C94.4252 20.0867 95.3428 19.703 95.9981 18.9333C96.6512 18.1587 97.0658 17.203 97.2374 16.0639H93.7293C92.6283 16.0639 91.8442 16.2641 91.3747 16.6644C90.9028 17.06 90.6693 17.5462 90.6693 18.123C90.6693 18.7474 90.9028 19.2288 91.3747 19.572C91.8442 19.9151 92.4662 20.0867 93.2431 20.0867Z"
          fill="#082623"
        ></path>
        <path
          d="M103.146 22.9175V6.94993H106.807L107.198 9.94326C107.775 8.91371 108.564 8.09388 109.562 7.48377C110.558 6.8689 111.736 6.55908 113.089 6.55908V10.9061H111.926C111.03 10.9061 110.225 11.0467 109.514 11.3255C108.809 11.6067 108.256 12.0882 107.856 12.7745C107.46 13.4609 107.265 14.419 107.265 15.6439V22.9175H103.146Z"
          fill="#082623"
        ></path>
        <path
          d="M121.433 23.3089C119.824 23.3089 118.397 22.9657 117.152 22.2793C115.906 21.5882 114.927 20.6206 114.216 19.3813C113.511 18.1373 113.158 16.6978 113.158 15.0629C113.158 13.4113 113.506 11.9433 114.207 10.6587C114.905 9.36939 115.87 8.36605 117.105 7.64632C118.337 6.92182 119.793 6.55957 121.471 6.55957C123.034 6.55957 124.417 6.90752 125.618 7.59866C126.819 8.28502 127.755 9.22401 128.43 10.4109C129.109 11.6001 129.45 12.9252 129.45 14.3861C129.45 14.622 129.443 14.8699 129.431 15.1296C129.424 15.3846 129.407 15.6516 129.383 15.9304H117.248C117.336 17.1768 117.772 18.1563 118.554 18.8665C119.336 19.572 120.284 19.9247 121.404 19.9247C122.243 19.9247 122.944 19.7388 123.511 19.3622C124.083 18.9881 124.507 18.4995 124.788 17.8942H128.973C128.668 18.9047 128.165 19.8246 127.467 20.6492C126.774 21.4762 125.916 22.1268 124.893 22.6034C123.876 23.0729 122.722 23.3089 121.433 23.3089ZM121.471 9.91515C120.46 9.91515 119.567 10.2011 118.792 10.7731C118.022 11.3403 117.529 12.2007 117.315 13.3565H125.265C125.201 12.3079 124.812 11.4738 124.102 10.8494C123.397 10.2273 122.52 9.91515 121.471 9.91515Z"
          fill="#082623"
        ></path>
        <path
          d="M136.2 23.3089C134.827 23.3089 133.697 23.0896 132.815 22.6511C131.938 22.2078 131.283 21.6192 130.852 20.8875C130.425 20.1582 130.213 19.3527 130.213 18.4757C130.213 16.9957 130.79 15.7946 131.948 14.8722C133.111 13.9523 134.848 13.49 137.162 13.49H141.223V13.0991C141.223 12.0076 140.911 11.2021 140.289 10.6873C139.665 10.1725 138.893 9.91515 137.973 9.91515C137.134 9.91515 136.402 10.1153 135.78 10.5157C135.156 10.9113 134.77 11.4928 134.617 12.2602H130.594C130.701 11.1044 131.092 10.0963 131.767 9.23831C132.446 8.38035 133.323 7.7202 134.398 7.25547C135.47 6.79313 136.671 6.55957 138.001 6.55957C140.275 6.55957 142.067 7.12916 143.378 8.26596C144.686 9.40514 145.342 11.0162 145.342 13.0991V22.918H141.833L141.443 20.3441C140.971 21.2021 140.311 21.9123 139.46 22.47C138.614 23.0276 137.527 23.3089 136.2 23.3089ZM137.134 20.0867C138.316 20.0867 139.233 19.703 139.889 18.9333C140.542 18.1587 140.956 17.203 141.128 16.0639H137.62C136.519 16.0639 135.735 16.2641 135.265 16.6644C134.793 17.06 134.56 17.5462 134.56 18.123C134.56 18.7474 134.793 19.2288 135.265 19.572C135.735 19.9151 136.357 20.0867 137.134 20.0867Z"
          fill="#082623"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_771_8469">
          <rect
            width="144.665"
            height="30"
            fill="white"
            transform="translate(0.667969)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

const Header = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  return (
    <div className="fixed top-0 z-50 w-full bg-[#f9fafd]">
      <header className="flex h-[82px] w-full flex-row items-center justify-between px-2 md:px-12">
        <div className="flex items-center">
          {/* logo */}
          <div className="w-[48px] h-[48px] bg-gray-400 me-2" />
          {/* <Image
             alt="Logo"
             loading="lazy"
             width="48"
             height="48"
             decoding="async"
             data-nimg="1"
             src="/mascotte/bras_croise.png"
          /> */}
          <ComperaTxt />
        </div>
        <div className="flex items-center gap-2">
          <LanguageChanger />
          <button
            className="shrink-0 items-center justify-center whitespace-nowrap rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[50px] px-[21px] w-fit flex"
            onClick={() => setShow(true)}
          >
            <PhoneIcon />
            <span className="md:inline-flex hidden">Remind me</span>
          </button>
        </div>
      </header>

      {/* Call modal */}
      <Modal dismissible show={show} onClose={() => setShow(false)} size={"xl"}>
        <Modal.Header className="border-0 pb-0 text-[20px] font-semibold items-center">
          {t("Header.key2")}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {/* Form */}
            <AdvisorCallForm isReminder />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
